import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { event } from 'jquery';


export default function Create_mandate_already_existing_opinion(props) {

  const {client_id} = useParams();
  const [opinion, setopinion] = useState({});
  const [alertopen, setalertOpen] = useState(false);
  const [alertType, setalertType] = useState();
  const [AlertMessage, setalertmessage] = useState();
  const btnRef = useRef(null);
  const alertRef = useRef(null);


  useEffect(()=>{

    const func = async ()=>{

      const res = await axios.get(`${url}/api/get_mandate__already_existing_Op/${client_id}`);
      const resp = res.data;
      setopinion(resp.opinion[0]);
      if(resp.status == true){
        btnRef.current.hidden = false;
      }
      else{
        if(resp.message == 'Mandate already exist'){
          if(btnRef.current){
            btnRef.current.hidden = true
          }
          if(alertRef.current){
            alertRef.current.hidden = false;
          }
        }
      }
    
    }

    func();


  },[])

  const submitForm = async (event)=>{
    event.preventDefault();
    const res = await axios.post(`${url}/api/enter_mandate_already_existing_Op`, {
      'id': client_id
    });
    const resp = res.data;
    
    if(resp.status == true){
      setalertType("success");
      setalertOpen(true);
      setalertmessage(resp.message);
      if(btnRef.current){
        btnRef.current.hidden = true
      }
    }
    else{
      setalertType("error");
      setalertOpen(true);
      setalertmessage('Some Error Occure while processing your request!');

    }

  }
  

  return (
    <div style={{height: '90vh'}}>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Enter New Mandate
        </div>
        <form className='col-10 card shadow p-3 mx-auto mt-lg-4 mt-md-4 mt-2' onSubmit={(event)=>{submitForm(event)}}>
            <div>
                <div className='mt-2 fw-bold'>
                    Opinion:
                </div>
                <div className='mt-2'>
                    <input className='form-control' disabled={true} value={opinion.name} />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Rating Type:
                </div>
                <div className='mt-2'>
                    <input className='form-control' disabled={true} value={opinion.rating_type} />
                </div>
            </div>
            <div>
                <div className='mt-2 fw-bold'>
                    Rating Scale:
                </div>
                <div className='mt-2'>
                    <input className='form-control' disabled={true} value={opinion.rating_scale} />
                </div>
            </div>
            <div className='mt-4' ref={alertRef} hidden={true}>
              <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                  <Collapse in={true}>
                      <Alert
                      severity={'error'}
                      action={
                          <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                              setalertOpen(false);
                          }}
                          >
                          <CloseIcon fontSize="inherit" />
                          </IconButton>
                      }
                      sx={{ mb: 2 }}
                      >
                      {'Mandate Already Exist'}
                      </Alert>
                  </Collapse>
              </Box>
            </div>
            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                  <Collapse in={alertopen}>
                      <Alert
                      severity={alertType}
                      action={
                          <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => {
                              setalertOpen(false);
                          }}
                          >
                          <CloseIcon fontSize="inherit" />
                          </IconButton>
                      }
                      sx={{ mb: 2 }}
                      >
                      {AlertMessage}
                      </Alert>
                  </Collapse>
              </Box>
            <div className='mt-4' ref={btnRef} hidden={true}>
                <button className='btn btn-primary' type='submit'>Submit</button>
            </div>

        </form>

    </div>
  )
}

