import React, { useState, useRef, useCallback } from 'react';
import JoditEditor from 'jodit-react';
import ReactHtmlParser from 'react-html-parser';


export default function MyEditor(props) {

    const editor = useRef(null);
	const [content, setContent] = useState('');

    const config = {
        buttons: [
          'bold', 
          'italic', 
          'underline', 
          'strikethrough', 
          'align', 
          'undo', 
          'redo', 
          'hr', 
          'link', 
          'fontsize', 
          'font', 
          'paragraph'
          // Exclude 'image' and 'video' here
        ],
        buttonsXS: ['bold', 'italic', 'underline'], // Optional for smaller screens
        toolbarSticky: false,
        toolbarAdaptive: false,
      };

      const handleEditorChange = useCallback((newContent) => {
        setContent(newContent);
        console.log(document.getElementById('content').value);
      }, []);
    

	return (
        <>
            <JoditEditor
                ref={editor}
                value={content}
                id={'content'}
                config={{
                    buttons: [
                      'bold', 'italic', 'underline', 'strikethrough', 'fontsize', 'font', 
                      'brush', 'align', 'list', 'paragraph', 'undo', 'redo', 'preview'
                    ],
                    uploader: { insertImageAsBase64URI: false },
                    filebrowser: { ajax: false },
                    // height: 400,
                  }}
          
                onChange={
                    newContent => {console.log(document.getElementById('content').value);}
                }

            />
            
            <div>
            { ReactHtmlParser(content) }
            </div>
        </>
	);

}

