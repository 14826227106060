import React from 'react'

export default function AddAnnouncement(props) {
  return (
    <div style={{height: '90vh'}}>
      <form className='col-8 card mx-auto p-3 mt-5'>
        <div className='fs-3 text-center fw-bold' style={{color: "#000078"}}>
            PACRA Announcments Form
        </div>
        <div className='mt-2'>
            <div className='fs-6'>
                Title:
            </div>
            <div className='mt-1'>
                <input className='form-control' required />
            </div>
            <div className='fs-6 mt-3'>
                Document Type:
            </div>
            <div className='mt-1'>
                <select className='form-select' required>
                    <option value={''}>Select Document Type</option>
                    <option value={'Rating Transitions/Movements'}>Rating Transitions/Movements</option>
                    <option value={'Best Practices Guiding Documents'}>Best Practices Guiding Documents</option>
                    <option value={'Financial Statement'}>Financial Statement</option>
                    <option value={'Directors Report'}>Directors Report</option>
                </select>
            </div>
            <div className='fs-6 mt-3'>
                Report (PDF/DOC):
            </div>
            <div className='mt-1'>
                <input className='form-control' type='file' required/>
            </div>
            <div className='fs-6 mt-3'>
                Uploaded Date:
            </div>
            <div className='mt-1'>
                <input className='form-control' type='date' required/>
            </div>
            <div className='mt-3'>
                <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
        </div>
      </form>
    </div>
  )
}
