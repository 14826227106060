import React, { useState, useEffect, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import white from "../../../Assets/Images/whitebg.jpg";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { url } from "../../../Config";
import { decryptParams } from '../encrypt_url';
import { useLocation } from 'react-router-dom';
import {encryptParams} from "../encrypt_url";




export default function Information(props) {
  const [tasks, setTasks] = useState([{ id: uuidv4() }]);
  const location = useLocation();
  const isadmin = localStorage.getItem('admin');
  const pathname = location.pathname;
  const encryptedParams = pathname.substring('/Ratings/addTask/Information/'.length);
  // Decrypt the parameters
  const { client_id, og_rating_id } = decryptParams(encryptedParams);
  const Navigate = useNavigate();
  const [data, setdata] = useState({});
  const [getsubtask, setGetsubtask] = useState([]);
  const [subtaskload, getsubtaskload] = useState("loading");
  const [taskload, gettaskload] = useState("loading");
  const [editfile, seteditfiles] = useState([]);
  const [editview, seteditview] = useState(false);
  const [file, setfiles] = useState([]);
  const [begin, setbegin] = useState("starting");
  const [title, settitle] = useState([]);
  const [date, setdate] = useState([]);
  const [alertopen, setalertOpen] = useState(false);
  const [del, setdel] = useState(false);
  const [alertType, setalertType] = useState();
  const [AlertMessage, setalertmessage] = useState();
  const [taskinc, settaskinc] = useState(0);
  const [delinc, setdelinc] = useState(0);
  const [editinc, seteditinc] = useState(0);
  const [changeopinion, setchangeopinion] = useState(false);
  const [opinion, setopinion] = useState([]);
  const [update_type_id, setupdate_type_id] = useState(0);
  const [isRcApproved, setisRcApproved] = useState(null);
  const [updateType, setupdateType] = useState([]);
  const [checktask, setchecktask] = useState([]);
  const [anexure, setanexure] = useState([]);
  const [reopen, setreopen] = useState(null);
  const processpage = "Information";

  const id = localStorage.getItem("userID");

  const selectRef = useRef();

  useEffect(()=>{
    if(id != "19" && id !== "42" && id !== "12"){
      const func = async ()=>{
        const res = await axios.get(`${url}/api/agreedinProcess/${id}`);
        const resp = res.data;
        const arr = [];
        for(let i in resp){
          if(resp[i].user_id == id || resp[i].lead_rc_id == id || resp[i].user_id1 == id){
            arr.push(resp[i]);
          }
        }
        setopinion(arr);
      }
      func();
    }
    else{
      const func = async ()=>{
        const resp2 = await axios.get(
          `${url}/api/myinprocess/${id}`
        );
        const data2 = resp2.data;
        const arr2 = [];
        for(let i in data2){
          if(data2[i].user_id == id || data2[i].lead_rc_id == id || data2[i].user_id1 == id || id == "42" || id == "12"){
              arr2.push(data2[i]);
          }
        } 
        setopinion(arr2);
      }

      func();
    }

  },[]);

  useEffect(()=>{ 
    const func = async ()=>{
      const res = await axios.get(`${url}/api/getRatingupdateType/${og_rating_id}`);
      const resp = res.data;
      setisRcApproved(resp.rc_upload_date);
      setupdateType(resp.update_type);
    }
    
    func();
  },[]);

  useEffect(() => {
    setdata({});
    const func = async () => {
      const resp = await axios.get(
        `${url}/api/addTask/FC/${client_id}/${og_rating_id}`
      );
      const res = resp.data;
      const response = res[0];

      if(response.edit_module_tasks !== null){
        let arr = response.edit_module_tasks.split(',');
        setchecktask(arr);

        }
      else{
        setchecktask([]);
      }
      
      setreopen(response.reopen);
      
      setdata(res[0]);
      setupdate_type_id(Number(res[0].rating_update_type));
    };
    func();
  }, [changeopinion]);

  useEffect(()=>{

    const func = async ()=>{
      const res = await axios.post(`https://polaris.pacra.com/get-annex-links`,{
        "record_id" : og_rating_id,
        "opinion_id": client_id
      });
      const resp = res.data;
      setanexure(resp);
    }

    func();

  },[])

  useEffect(() => {
    if(del == true){
      setGetsubtask([]);
      setdel(false);
    }
    const func = async () => {

      const getsubtasks = await axios.get(
        `${url}/api/getsubTask/Information/${client_id}/${og_rating_id}`
      );

      if(getsubtask != null){
        setGetsubtask(getsubtasks.data);
        
        if(begin == "starting"){
          seteditfiles(Array(getsubtasks.data.length).fill(null));
          setbegin("started");
        }
      }
      getsubtaskload("loaded");
    };

    func();

  }, [editview]);

  useEffect(()=>{
    setGetsubtask(getsubtask);
  },[getsubtask])

  useEffect(()=>{
    gettaskload("loading");
    setbegin("starting");
    setTasks([]);
    const func = async () => {
      const getsubtasks = await axios.get(
        `${url}/api/getsubTask/Information/${client_id}/${og_rating_id}`
      );
      if(getsubtask != null){
        setGetsubtask(getsubtasks.data);
        if(begin == "starting"){
          seteditfiles(Array(getsubtasks.data.length).fill(null));
          setbegin("started");
        }
      }
      setTasks([{ id: uuidv4() }]);
      gettaskload("loaded");
    };

    func ()
  },[changeopinion])

  const changeDateFormate =  (date)=>{
    const timestamp = new Date(date);

    // Extract the date part
    const year = timestamp.getFullYear();
    const month = String(timestamp.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
    const day = String(timestamp.getDate()).padStart(2, '0');

    // Format the date part
    const datePart = `${year}-${month}-${day}`;
    return datePart;

  }

  const editSubTasks = async (id, index) => {
    settaskinc(0);
    setdelinc(0);
    const fileData = new FormData();
    const date = document.getElementById(`editDate-${index}`).value;
    const title = document.getElementById(`editTitle-${index}`).value;
    if(date !== null && title !==null){
      seteditinc(editinc + 1);
      document.getElementById(`editicon${index}`).style.display = "none";
      document.getElementById(`editprogress${index}`).style.display = "unset";
      if (editfile[index] == null) {
        console.log(editfile[index]);
        fileData.append("fileupload", null);
        fileData.append("title", title);
        fileData.append("date", date);
        fileData.append("id", localStorage.getItem("userID"));
        fileData.append("recordid", Number(og_rating_id));
        const editapi = await axios.post(
          `${url}/api/updatesubTask/${id}`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        document.getElementById(`editicon${index}`).style.display = "unset";
        document.getElementById(`editprogress${index}`).style.display = "none";
        if(editapi.data.status == true){
          setalertType("success");
          setalertOpen(true);
          if(editinc >= 1){
            setalertmessage(`Task Edit Succefully! (${editinc})`)
          }
          else{
            setalertmessage(`Task Edit Succefully!`)
          }
        }
        else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(`An error occure while Editing Task. Please try again!`)
        }
        
      } 
      else {
        console.log(editfile[index], 'not null');
        fileData.append("fileupload", "not null");
        fileData.append("file", editfile[index]);
        fileData.append("title", title);
        fileData.append("date", date);
        fileData.append("recordid", Number(og_rating_id));
        fileData.append("id", localStorage.getItem("userID"));
  
        const editapi = await axios.post(
          `${url}/api/updatesubTask/${id}`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        document.getElementById(`editicon${index}`).style.display = "unset";
        document.getElementById(`editprogress${index}`).style.display = "none";
        if(editapi.data.status == true){
          seteditview(!editview);
          const neweditfile = [...editfile];
          neweditfile[index] = null;
          seteditfiles(neweditfile);
          console.log(neweditfile, "editfile");
          setalertType("success");
          setalertOpen(true);
          if(editinc >= 1){
            setalertmessage(`Task Edit Succefully! ${editinc}`)
          }
          else{
            setalertmessage(`Task Edit Succefully!`)
          }
        }
        else{
            setalertType("error");
            setalertOpen(true);
            setalertmessage(`An error occure while Editing Task. Please try again!`)
        }
      }
    }
    else{
      setalertType("error");
      setalertOpen(true);
      setalertmessage(`Task Title or Date is Empty. Check it Out!`)
    }
  };

  const deleteSubTasks = async (id, index, url2) => {
    Swal.fire({ title: 'Do you want to delete the task?', 
    showDenyButton: false, showCancelButton: true, confirmButtonText: 'Delete'})
    .then(async(result) => {
      if (result.isConfirmed) {
        if(url2 == 'polaris'){
          alert('Not allowed to delete task');
        }
        else{
          settaskinc(0);
          seteditinc(0);
          setdelinc(delinc + 1);
          document.getElementById(`deleteicon${id}`).style.display = "none";
          document.getElementById(`deleteprogress${id}`).style.display = "unset";
          const res = await axios.get(
            `${url}/api/deletesubTask/${id}/${og_rating_id}`
            );
            console.log(res, "response on delete");
            document.getElementById(`deleteicon${id}`).style.display = "unset";
            document.getElementById(`deleteprogress${id}`).style.display = "none";
            if (res.data.message == "Task Deleted!") {
            setdel(true);
            seteditview(!editview);
            const Editfiles = [...editfile];
            Editfiles.splice(index, 1);
            seteditfiles(Editfiles);
            setalertType("success");
            setalertOpen(true);
            if(delinc >= 1){
                setalertmessage(`Task deleted Succefully!(${delinc})`);
            }
            else{
                setalertmessage(`Task deleted Succefully!`);
            }
        }
        else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(`An Error Occure while deleting Task`);
        }

        }
      }
    })
    
  };

  const addTask = async (id, index) => {
    seteditinc(0);
    setdelinc(0);
    const fileData = new FormData();
    if (date[index]&& title[index] && file[index] !== undefined && file[index] !== null) {
      document.getElementById(`saveicon${id}`).style.display = "none";
      document.getElementById(`saveprogress${id}`).style.display = "unset";
        settaskinc(taskinc + 1);
        fileData.append("fileupload", "not null");
        fileData.append("file", file[index]);
        fileData.append("taskTitle", "Information");
        fileData.append("taskName", title[index]);
        fileData.append("taskDate", date[index]);
        fileData.append("record_id", og_rating_id);
        fileData.append("opinion_id", client_id);
        fileData.append("analystid", data.analyst_id);
        fileData.append("teamleadid", data.manager_id);
        fileData.append("unitheadid", data.unit_head_id);
        fileData.append("id", localStorage.getItem("userID"));

        const res = await axios.post(
          `${url}/api/addtask`,
          fileData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (res.data.status == true) {
            const Editfiles = [...editfile];
            Editfiles.push(null);
            seteditfiles(Editfiles);
            seteditview(!editview)
            if (tasks.length == 1) {
              console.log(tasks.filter((task) => task.id !== id));
              const newtitle = [...title];
              newtitle.splice(index, 1);
              settitle(newtitle);
              const newdate = [...date];
              newdate.splice(index, 1);
              setdate(newdate);
              setTasks(tasks.filter((task) => task.id !== id));
              setTasks([{ id: uuidv4() }]);
              const newFiles = [...file];
              newFiles.splice(index, 1)
              setfiles(newFiles);
              seteditview(!editview);
            } else {
              console.log(tasks.filter((task) => task.id !== id));
              const newtitle = [...title];
              newtitle.splice(index, 1);
              settitle(newtitle);
              const newdate = [...date];
              newdate.splice(index, 1);
              setdate(newdate);
              setTasks(tasks.filter((task) => task.id !== id));
              const newFiles = [...file];
              newFiles.splice(index, 1)
              setfiles(newFiles);
              seteditview(!editview);
              
            }
            setalertType("success");
            setalertOpen(true);
            if(taskinc > 1){
              setalertmessage(`Task Added Successfully And added in edit section! (${taskinc})`)
            }
            else{
              setalertmessage(`Task Added Successfully And added in edit section!`)
            }
        }
        else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(`An error occure while adding Task. Please try again!`)
          document.getElementById(`saveicon${id}`).style.display = "unset";
          document.getElementById(`saveprogress${id}`).style.display = "none";
        }
    } 
    else {
      setalertType("error");
      setalertOpen(true);
      setalertmessage(`Check Date, Title and File field in Row ${index + 1}! It should not be empty`);
    }
  };

  const handleAddTask = () => {
    setTasks([...tasks, { id: uuidv4() }]);
  };

  const handleDeleteTask = (id, index) => {
    setTasks(tasks.filter((task) => task.id !== id));
    const newtitle = [...title];
    newtitle.splice(index, 1);
    settitle(newtitle)
    const newdate = [...date];
    newdate.splice(index, 1);
    setdate(newdate)
    const newfile = [...file];
    newfile.splice(index, 1);
    setfiles(newfile);
  };

  const preventKeyboardInput = (e) => {
    e.preventDefault();
  };

  const renderTasks = () => {
    return tasks.map((task, index) => {
      const { id } = task;
      const showAddButton = index === tasks.length - 1;
      return (
        <>
          <div key={id} className="row w-100 pt-2 ps-2 pb-1" id={`task-${id}`}>
            <div className="col-lg-4 col-md-5 col-sm-5 col-7 ms-lg-5 ms-1">
              <input
                className="form-control"
                id={`title-${id}`}
                type="text"
                placeholder="Task Name"
                value={title[index]}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    const newtitle = [...title];
                    newtitle[index] = e.target.value
                    settitle(newtitle);
                  } else {
                    const newtitle = [...title];
                    newtitle[index] = null
                    settitle(newtitle);
                  }
                }}
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-4 ms-lg-2 ms-1">
              <input
                className="form-control"
                id={`date-${id}`}
                type="date"
                onInvalid={()=>{alert('wrong date')}}
                value={date[index]}
                min={data.initiation_date}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    const newdate = [...date];
                    newdate[index] = e.target.value
                    setdate(newdate);
                  } else {
                    const newdate = [...date];
                    newdate[index] = null
                    setdate(newdate);
                  }
                }}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12 ms-lg-2 ms-1 mt-sm-0 mt-1 d-flex">
              <div className="col-11">
                <label className="btn btn-primary" style={{ height: "85%", width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <input
                  className="form-control d-none"
                  id={`file-${id}`}
                  type="file"
                  onChange={(e) => {
                    const newFiles = [...file];
                    newFiles[index] = e.target.files[0];
                    console.log(newFiles);
                    setfiles(newFiles);
                  }}
                />
                {file[index] ? (
                  <span title={file[index].name}>{file[index].name}</span>
                ) : (
                  "Upload File"
                )}
                </label>
              </div>
            </div>
            <div className="col-lg-2 col-md-1 col-sm-1 col-2 ms-lg-1 ms-1 ms-md-1 mt-lg-0 mt-1 d-flex">
              <div type="submit" onClick={() => addTask(id, index)}>
                <div className="mt-0" id={`saveicon${id}`}>
                  <SaveIcon style={{color: "#000078"}} />
                </div>
                <div className="mt-0" id={`saveprogress${id}`} style={{ display: "none" }}>
                  <CircularProgress size={15} />
                </div>
              </div>
              {tasks.length > 1 && (
                <div
                  className="ms-1"
                  onClick={() => handleDeleteTask(id, index)}
                >
                    <CancelIcon style={{color: "#dc3545"}} />
                </div>
              )}
              {showAddButton && (
                <div
                  className="ms-1"
                  onClick={handleAddTask}
                >
                    <AddCircleOutlinedIcon style={{color: "#198754"}} />
                </div>
              )}
            </div>
          </div>
        </>
      );
    });
  };

  const getSubTasksFunc = () => {
    return getsubtask.map((value, index) => {
      return (
        <>
          <div className="row w-100 pt-2 ps-2 pb-1" id={index}>
            <div className="col-lg-4 col-md-5 col-sm-5 col-7 ms-lg-5 ms-1">
              <input
                className="form-control"
                id={`editTitle-${index}`}
                defaultValue={value.title}
                type="text"
              />
            </div>
            <div className="col-lg-2 col-md-3 col-sm-3 col-4 ms-lg-2 ms-1">
              <input
                className="form-control"
                id={`editDate-${index}`}
                defaultValue={value.date}
                min={data.initiation_date}
                readonly
                type="date"
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-12 ms-lg-2 ms-1 d-flex mt-sm-0 mt-1">
              <div className="col-11">
              <label className="btn btn-primary" style={{ height: "85%", width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                <input
                  className="form-control d-none"
                  id={`editUpload-${index}`}
                  type="file"
                  onChange={(e) => {
                    if(value.url == 'polaris'){
                      alert('Not allowed to change/upload file!');
                      return;
                    }
                    else{
                      const newEditFiles = [...editfile];
                      newEditFiles[index] = e.target.files[0];
                      seteditfiles(newEditFiles);
                    }
                  }}
                />
                {editfile[index] ? (
                  <span title={editfile[index].name}>{editfile[index].name}</span>
                ) : (<>{value.task_file && "Change File"} {value.task_file == null && "Upload File"}</>)}
              </label>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 col-12 ms-lg-1 ms-1 ms-md-1 mt-lg-0 mt-1 d-flex">
              {value.task_file != null ?
                <div className="col-1">
                  {
                    value.status == 'Spaces' ? 
                    <a href={`${url}/api/viewsubtask_spaces/${value.task_file}`} target="_blank">
                    <DescriptionIcon />
                    </a> :
                    <a href={`${url}/api/storage/app/storage/task_files/${value.task_file}`} target="_blank">
                    <DescriptionIcon />
                    </a>
                  }
                  
                </div> : null}
                <div onClick={() => editSubTasks(value.id, index)}>                  
                <div
                  className="mt-0"
                  id={`editprogress${index}`}
                  style={{ display: "none" }}
                >
                  <CircularProgress size={15} />
                </div>
                <div className="mt-0 ms-1" id={`editicon${index}`}>
                <EditIcon style={{color: "#ffc107"}} />
                </div>

                </div>

                <div className="ms-1" onClick={() => {deleteSubTasks(value.id, index, value.url)}}>
                  <div
                    className="mt-0 ms-1"
                    id={`deleteprogress${value.id}`}
                    style={{ display: "none" }}
                  >
                    <CircularProgress size={15} />
                  </div>
                  <div className="mt-0" id={`deleteicon${value.id}`}>
                    <DeleteIcon style={{color: "#dc3545"}} />
                  </div>
                </div>

                
            </div>
            <hr />
          </div>
        </>
      );
    });
  };

  const changeUpdateType = async (value)=>{
    if(value !== '0'){
      setalertOpen(false);
      const res = await axios.post(`${url}/api/changeRatingupdateType`, {
        'id' : Number(og_rating_id),
        'rating_update_type' : Number(value)
      });
      const resp = res.data;
      if(resp.status == true){
          setalertType("success");
          setalertOpen(true);
          setalertmessage(resp.message);
          setupdate_type_id(value);
      }
      else{
          setalertType("error");
          setalertOpen(true);
          setalertmessage(resp.message);
      }

    }

  }

  return (
    <div style={{height: "90vh"}}>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        Information
      </div>
      <div
        className="card m-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
      >
        <div className="row p-2">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw" }}
                >
                  CRO
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.lead_name}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Team Lead
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.manager_name}
                ></input>
              </div>
            </div> 
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Analyst{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <input
                  className="form-control"
                  disabled
                  type="text"
                  defaultValue={data && data.username}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  {" "}
                  Opinion{" "}
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
                <select className="form-select" value={`${client_id},${og_rating_id}`} onChange={(e)=>{
                  const val = e.target.value;
                  const arr = val.split(",");
                  const client_id = arr[0];
                  const og_rating_id = arr[1];
                  const encryptedParams = encryptParams({client_id, og_rating_id})
                  Navigate(`/Ratings/addTask/Information/${encryptedParams}`);
                  setGetsubtask([]);
                  setchangeopinion(!changeopinion);
                }}>
                  {
                    opinion.map((value, index)=>{
                      return(
                        <option value={`${value.Id},${value.record_id}`}>{value.Entity}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Rating Update Type
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
              {isRcApproved == null ?
                <select className="form-select" ref={selectRef} onChange={(e)=>{

                  Swal.fire({ title: 'Are you sure you want to change The Rating Type?', 
                  showDenyButton: false, showCancelButton: true, confirmButtonText: 'Confirm'})
                  .then(async(result) => {
                    if (result.isConfirmed) {
                      changeUpdateType(e.target.value)
                    }
                    else{
                      selectRef.current.value = update_type_id;
                    }
                    })

                  }}>
                    <option value={0}>Select Review Type</option>
                      {
                        updateType.map((value, index)=>{
                          return(
                            <option value={value.id} selected={value.id == update_type_id ? true : false}>{value.title}</option>
                          );
                        })
                      }
                  </select> :
                  <input
                    className="form-control"
                    disabled
                    type="text"
                    defaultValue={data && data.ratingUpdateType_title}
                  ></input>
              }
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mb-1">
            <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-3 col-12 mt-1">
                <label
                  className="ms-lg-5 ms-md-5 ms-1 fw-bold fs-6"
                  style={{ color: "#000078", fontFamily: "Kalinga", fontSize:"0.5vw"  }}
                >
                  Process
                </label>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 ms-lg-3 ms-md-3">
              <select className="form-select" defaultValue={processpage} onChange={(e)=>{
                const encryptedParams = encryptParams({client_id, og_rating_id})
                // if(e.target.value == "Information" || e.target.value == "Assessment"){
                //   Navigate(`/Ratings/addTask/${e.target.value}/${encryptedParams}`);
                // }
                // else{
                //   Navigate(`/Ratings/addTask/${e.target.value}/${client_id}/${og_rating_id}`);
                // }
                Navigate(`/Ratings/addTask/${e.target.value}/${encryptedParams}`);
              }}>
                <option value={"Information"}>Information</option>
                <option value={"Assessment"}>Assessment</option>
                <option value={"SiteVisit"}>Site Visit</option>
                <option value={"MM"}>MM</option>
                <option value={"RC"}>RC</option>
                <option value={"PPL"}>PPL</option>
                <option value={"NL"}>NL</option>
                <option value={"RR"}>RR</option>
                <option value={"RED"}>RED</option>
              </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isadmin == 'true' &&
      <div className="text-center">
            <b>Record id:</b> {og_rating_id} <b>Opinion id:</b> {client_id}
      </div>}
        <Box className="mx-auto" sx={{ width: "98%" }}>
          <Collapse in={alertopen}>
            <Alert
              severity={alertType}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setalertOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {AlertMessage}
            </Alert>
          </Collapse>
        </Box>

        <div
        className="card m-2 mb-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
        >
          {
            anexure.map((value, index)=>{
              return(
                <>
                  <div className="row w-100 pt-2 ps-2 pb-1" id={index}>
                    <div className="col-lg-4 col-md-5 col-sm-5 col-7 ms-lg-5 ms-1">
                      <input
                        className="form-control"
                        id={`editTitle-${index}`}
                        defaultValue={value.title}
                        type="text"
                      />
                    </div>
                    <div className="col-lg-2 col-md-3 col-sm-3 col-4 ms-lg-2 ms-1">
                      <input
                        className="form-control"
                        value={changeDateFormate(value.updated_at)}
                        type="date"
                        readonly
                      />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 ms-lg-2 ms-1 d-flex mt-sm-0 mt-1">
                    <div className="col-11">
                      <button className="btn btn-primary" disabled style={{ height: "85%", width: "100%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        Change/Upload File
                      </button>
                    </div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-2 col-12 ms-lg-1 ms-1 ms-md-1 mt-lg-0 mt-1 d-flex">
                      {value.link != null && 
                        <div className="col-1">
                          <a href={value.link} target="_blank">
                            <DescriptionIcon />
                          </a>
                        </div>}
                    </div>
                      <hr/>
                  </div>
                </>
              )
            })
          }
        </div>

      {
        (reopen == 'Open' && checktask.includes('Information') == true) ?
        <div
        className="card m-2 mb-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
        >

        {subtaskload == "loaded" ? (
          getSubTasksFunc()
        ) : (
          <center>
            <CircularProgress className="mt-1" />
          </center>
        )}
        {tasks.length !== 0 && renderTasks()}
        {/* <div className='ms-lg-5 ms-1'>
        <button className='btn btn-primary ms-2 mb-1' type='button' onClick={handleAddTask}>Add More</button>
      </div> */}
        </div> :
        reopen == null ?
        <div
        className="card m-2 mb-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
      >

        {subtaskload == "loaded" ? (
          getSubTasksFunc()
        ) : (
          <center>
            <CircularProgress className="mt-1" />
          </center>
        )}

        {tasks.length !== 0 && renderTasks()}
        {/* <div className='ms-lg-5 ms-1'>
        <button className='btn btn-primary ms-2 mb-1' type='button' onClick={handleAddTask}>Add More</button>
      </div> */}
        </div> :
        (reopen == 'Open' && checktask.includes('Information') == false) ?
        <div className="mt-3 fw-4 text-center"> Not allowed to Edit after File Closure</div> :
        <div
        className="card m-2 mb-2 shadow"
        style={{
          backgroundColor: "#e0e0e0",
          backgroundImage: `url(${white})`,
          backgroundSize: "cover",
        }}
      >

        {subtaskload == "loaded" ? (
          getSubTasksFunc()
        ) : (
          <center>
            <CircularProgress className="mt-1" />
          </center>
        )}
        {tasks.length !== 0 && renderTasks()}
        {/* <div className='ms-lg-5 ms-1'>
        <button className='btn btn-primary ms-2 mb-1' type='button' onClick={handleAddTask}>Add More</button>
      </div> */}
        </div>
      } 
      <div className="ms-2">
        {data &&
          data.rating_type == "Instrument" &&
          data.rating_action == "Initial" && (
            <div className="col-10 fs-6 mb-2" style={{ fontFamily: "Kalinga" }}>
              <b>Note:</b> <b>Final Term Sheet</b> is required from this
              Process. If Final Term Sheet is not uploaded then you won't be
              allowed to make File Closure. When Uploading Final Term Sheet the
              task name should be saved as <b>"Final Term Sheet"</b> or else
              File Closure won't be able to recognize the task.{" "}
            </div>
          )}
        {data &&
          data.rating_type == "Instrument" &&
          data.rating_action == "Preliminary" && (
            <div className="col-10 fs-6 mb-2" style={{ fontFamily: "Kalinga" }}>
              <b>Note:</b> <b>Term Sheet</b> is required from this Process. If
              Term Sheet is not uploaded then you won't be allowed to make File
              Closure. When Uploading Term Sheet the task name should be saved
              as <b>"Term Sheet"</b> or else File Closure won't be able to
              recognize the task.{" "}
            </div>
          )}
        {data && data.rating_type == "Mutual Fund" && (
          <div className="col-10 fs-6 mb-2" style={{ fontFamily: "Kalinga" }}>
            <b>Note:</b> <b>Offering Document</b> is required from this Process.
            If Offering Document is not uploaded then you won't be allowed to
            make File Closure. When Uploading Offering Document the task name
            should be saved as <b>"Offering Document"</b> or else File Closure
            won't be able to recognize the task.{" "}
          </div>
        )}
      </div>
    </div>
  );
}
