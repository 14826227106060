import React, {useState, useEffect, useRef} from 'react';
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { url } from '../../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import Select from "react-select";



export default function Mandate_revise(props) {

    const [rating_types, setrating_types] = useState([]);
    const [clients, setclients] = useState([]);
    const [rating_scale, setrating_scale] = useState([]);
    const [rating_type_id, setrating_type_id] = useState([]);
    const [rating_scale_id, setrating_scale_id] = useState([]);
    const [clientt, setclientt] = useState(null);
    const [hidescale, sethidescale] = useState(false);
    const [loadmessage, setloadmessage] = useState("");
    const clientref = useRef(null);
    const btnref = useRef(null);
    const mandatebtnref = useRef(null);
    const rating_typeRef = useRef(null);
    const rating_scaleRef = useRef(null);
    const [selectedOptions, setSelectedOptions] = useState();
    const [opdata, setopdata] = useState([]);



    
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();


    useEffect(()=>{
        const func = async ()=>{
            const res = await axios.get(`${url}/api/revisemandate`);
            const resp = res.data;
            setrating_types(resp.rating_types);

        }

        func();
    },[])

    const submitform = async (event)=>{
        event.preventDefault();
        try {
            
            if(clientt == null){
                setalertType("error");
                setalertOpen(true);
                setalertmessage(`Select Client First!`);
                return ;
            }

            

            btnref.current.disabled = true;

            const res = await axios.post(`${url}/api/onrevisemandate`, {
                'approvedclients' : clientt

            })

            const resp = res.data;
            
            if(resp.status == true){
                btnref.current.disabled = false;
                setalertType("success");
                setalertOpen(true);
                setalertmessage(resp.message);
                setclientt(null);
                setclients([]);
                setSelectedOptions();
                setloadmessage("loading");
                const res1 = await axios.get(`${url}/api/getclients/${rating_type_id}/${rating_scale_id}`);
                const resp1 = res1.data;
                const mergedArray = Array.from(new Map([...resp1.outstanding, ...resp1.initial, ...resp1.cob, ...resp1.icu].map(obj => [obj.Id, obj])).values());

                const mappedOptions = mergedArray.map(item => ({ value: item.Id, label: item.Entity }));
                setopdata(mappedOptions);
                setclients(mergedArray);
                setloadmessage("loaded");


            }
            else{
                btnref.current.disabled = false;
                setalertType("error");
                setalertOpen(true);
                setalertmessage(resp.message);
                if(resp.message == 'No record found in pacra_ReMandates table. plz contact MIT'){
                    if(mandatebtnref.current){
                        mandatebtnref.current.hidden = false;
                    }
                }
            }
        } catch (error) {
            btnref.current.disabled = false;
            setalertType("error");
            setalertOpen(true);
            setalertmessage('some error occure while processing request!');
        }
    }

    function handleSelect(data) {
        if(mandatebtnref.current){
            mandatebtnref.current.hidden = true;
        }
        setclientt(data.value);
        setSelectedOptions(data);
    }



  return (
     <div style={{height: '90vh'}}>
        <div className='mt-4 col-10 mx-auto'>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to={"/bd_dashboard"} >Dashboard</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Mandate Revised</li>
                </ol>
            </nav>
            <hr/>
        </div>
       <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
            Revise Mandate
        </div>
        <form className='col-10 card shadow p-3 mx-auto mt-lg-4 mt-md-4 mt-2' onSubmit={(event)=>{submitform(event)}}>
            <div>
                <div className='mt-2 fw-bold'>
                    Rating Type:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_typeRef} onChange={async (e)=>{
                        if(e.target.value == ""){
                            sethidescale(false);
                            setrating_scale([]);
                            setclients([]);
                        }
                        else{
                            for(let i in rating_types){
                            
                                if(rating_types[i].id == e.target.value){

                                    setrating_scale(rating_types[i].rating_scale);
                                    setrating_scale_id(rating_types[i].rating_scale[0].id)
                                    setrating_type_id(e.target.value);
                                    setclientt(null);
                                    setclients([]);
                                    setSelectedOptions();
                                    setloadmessage("loading");
                                    const res = await axios.get(`${url}/api/getclients/${e.target.value}/${rating_types[i].rating_scale[0].id}`);
                                    const resp = res.data;
                                    const mergedArray = Array.from(new Map([...resp.outstanding, ...resp.initial, ...resp.cob, ...resp.icu].map(obj => [obj.Id, obj])).values());
                                    const mappedOptions = mergedArray.map(item => ({ value: item.Id, label: item.Entity }));
                                    setopdata(mappedOptions);
                                    setclients(mergedArray);
                                    setloadmessage("loaded");
                                    sethidescale(true);
                                }
                            }
                        }
                        
                    }}>
                        <option value={''}>Select Rating Type</option>
                        {
                            rating_types.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            <div>
                <div className='mt-3 fw-bold'>
                    Rating Scale:
                </div>
                <div className='mt-2'>
                    <select className='form-select' ref={rating_scaleRef} onChange={async (e)=>{

                            setclientt(null);
                            setclients([]);
                            setloadmessage("loading");
                            setrating_scale_id(e.target.value);
                            setrating_type_id(rating_typeRef.current.value);
                            setSelectedOptions();
                            const res = await axios.get(`${url}/api/getclients/${rating_typeRef.current.value}/${e.target.value}`);
                            const resp = res.data;
                            const mergedArray = Array.from(new Map([...resp.outstanding, ...resp.initial, ...resp.cob, ...resp.icu].map(obj => [obj.Id, obj])).values());
                            const mappedOptions = mergedArray.map(item => ({ value: item.Id, label: item.Entity }));
                            setopdata(mappedOptions);
                            setclients(mergedArray);
                            setloadmessage("loaded");


                    }}>
                        {hidescale == false && <option value={''}>Select Rating Scale</option>}
                        {
                            rating_scale.map((value, index)=>{
                                return(
                                    <option value={value.id}>{value.title}</option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
            {clients.length == 0 && loadmessage == "loading" ?
            <div>Please Wait while clients are loading...</div> :
            clients.length == 0 && loadmessage == "loaded" ?
            <div>No Clients Available</div> :
            clients.length > 0 ?
            <div>
                <div className='mt-3 fw-bold'>
                    Clients:
                </div>
                <div className='mt-2'>
                    <Select
                        options={opdata}
                        placeholder="Select Opinion"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        // isMulti
                        />
                </div>
            </div> : null
            }

            <Box className="mx-auto mt-1 mb-1" sx={{ width: "98%" }}>
                <Collapse in={alertopen}>
                    <Alert
                    severity={alertType}
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setalertOpen(false);
                        }}
                        >
                        <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                    >
                    {AlertMessage}
                    </Alert>
                </Collapse>
            </Box>
            <div className='mt-4'>
                <button className='btn btn-danger' ref={mandatebtnref} hidden={true} type='button' onClick={()=>{window.open(`/already-existing-opinion/${clientt}`)}}>Enter Mandate</button>
            </div>
            <div className='mt-4'>
                <button className='btn btn-primary' ref={btnref} type='submit'>Revise Mandate</button>
            </div>
        </form>
    </div>
  )
}
